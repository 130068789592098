import { Injectable } from '@angular/core';
import { ToastrService, GlobalConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  config = {
    enableHtml: true,
    positionClass: 'toast-bottom-right',
    timeOut: 2000,
    closeButton: false,
  };
  constructor(private toastrService: ToastrService) {}
  info(message: string, infoHeader: string, title?: string) {
    let html = '';
    html += '<div class="ribbon-wrapper card info">';
    html += '  <div class="card-body">';
    html += '   <div class="header">';
    html +=
      '     <div class="ribbon ribbon-clip ribbon-primary">' +
      infoHeader +
      '</div>';
    html += '      <i class="fa fa-close" style="font-size:24px"></i>';
    html += '   </div>';
    if (title) {
      html += '<h6>' + title + '</h6>';
    }
    html += '   <p>' + message + '</p>';
    html += '  </div>';
    html += '</div>';
    this.toastrService.show(html, undefined, this.config);
  }
  success(message: string, successHeader: string, title?: string) {
    let html = '';
    html += '<div class="ribbon-wrapper card success">';
    html += '  <div class="card-body">';
    html += '   <div class="header">';
    html +=
      '     <div class="ribbon ribbon-clip ribbon-success">' +
      successHeader +
      '</div>';
    html += '      <i class="fa fa-close" style="font-size:24px"></i>';
    html += '   </div>';
    if (title) {
      html += '<h6>' + title + '</h6>';
    }
    html += '   <p>' + message + '</p>';
    html += '  </div>';
    html += '</div>';
    this.toastrService.show(html, undefined, this.config);
  }
  warning(message: string, warningHeader: string, title?: string) {
    let html = '';
    html += '<div class="ribbon-wrapper card warning">';
    html += '  <div class="card-body">';
    html += '   <div class="header">';
    html +=
      '     <div class="ribbon ribbon-clip ribbon-warning">' +
      warningHeader +
      '</div>';
    html += '      <i class="fa fa-close" style="font-size:24px"></i>';
    html += '   </div>';
    if (title) {
      html += '<h6>' + title + '</h6>';
    }
    html += '   <p>' + message + '</p>';
    html += '  </div>';
    html += '</div>';
    this.toastrService.show(html, undefined, this.config);
  }
  error(message: string, errorHeader: string, title?: string) {
    let html = '';
    html += '<div class="ribbon-wrapper card danger">';
    html += '  <div class="card-body">';
    html += '   <div class="header">';
    html +=
      '     <div class="ribbon ribbon-clip ribbon-danger">' +
      errorHeader +
      '</div>';
    html += '      <i class="fa fa-close" style="font-size:24px"></i>';
    html += '   </div>';
    if (title) {
      html += '<h6>' + title + '</h6>';
    }
    html += '   <p>' + message + '</p>';
    html += '  </div>';
    html += '</div>';
    this.toastrService.show(html, undefined, this.config);
  }

  clear() {
    this.toastrService.clear();
  }
}
